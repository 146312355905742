import React from "react";
import { Form as FinalForm, Field } from "react-final-form";
import * as Yup from "yup";
import * as palette from "@govlaunch/palette";
import validate from "~/lib/utils/validate";
import { LinkIcon, DocumentIcon } from "~/components/product/resources/icons";
import { Margin } from "~/components/spacings";
import { Filestack, Button } from "@govlaunch/core";
import { Anchor } from "@govlaunch/web";
import { useIntl, FormattedMessage } from "react-intl";
import useIntlValidationMessages from "~/lib/hooks/useIntlValidationMessages";

interface IResourceForm {
  onSubmit: (resource: any) => any;
  onClose: any;
  color: string;
}

interface IResourceFieldError {
  meta: any;
}

const ResourceForm: React.FunctionComponent<IResourceForm> = ({ onSubmit, onClose, color }) => {
  const intl = useIntl();
  const intlValidationMessages = useIntlValidationMessages();

  return (
    <FinalForm
      keepDirtyOnReinitialize={true}
      initialValues={{
        type: "link",
      }}
      onSubmit={onSubmit}
      validate={(values) => {
        if (values.type === "download") {
          return validate(
            Yup.object().shape({
              title: Yup.string().required(intlValidationMessages.cantBeBlank),
              url: Yup.string().url(intlValidationMessages.invalidUrl).required(intlValidationMessages.cantBeBlank),
            }),
          )(values);
        }

        if (values.type === "link") {
          return validate(
            Yup.object().shape({
              title: Yup.string().required(intlValidationMessages.cantBeBlank),
              url: Yup.string().url(intlValidationMessages.invalidUrl).required(intlValidationMessages.cantBeBlank),
            }),
          )(values);
        }

        return validate(
          Yup.object().shape({
            title: Yup.string().required(intlValidationMessages.cantBeBlank),
            body: Yup.string().required(intlValidationMessages.cantBeBlank),
          }),
        )(values);
      }}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <div
            css={{
              width: 350,
              padding: 15,
              marginTop: 10,
            }}
          >
            <Field name="type">
              {({ input }) => (
                <div
                  css={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: 70,
                    margin: "0 auto",
                  }}
                >
                  <div
                    css={{
                      position: "relative",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={() => input.onChange("link")}
                  >
                    <LinkIcon width={24} height={24} color={color} />
                    {values.type === "link" && (
                      <div
                        css={{
                          height: 2,
                          width: "100%",
                          position: "absolute",
                          bottom: -4,
                          left: 0,
                          background: color,
                        }}
                      />
                    )}
                  </div>

                  <div
                    css={{
                      position: "relative",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={() => input.onChange("download")}
                  >
                    <DocumentIcon width={24} height={24} color={color} />
                    {values.type === "download" && (
                      <div
                        css={{
                          height: 2,
                          width: "100%",
                          position: "absolute",
                          bottom: -4,
                          left: 0,
                          background: color,
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </Field>

            <div
              css={{
                paddingTop: 15,
                paddingBottom: 15,
              }}
            >
              <Field name="title">
                {({ input, meta }) => (
                  <>
                    <input
                      css={{
                        height: 42,
                        borderRadius: 5,
                        border: `1px solid ${palette.lightestGray}`,
                        fontSize: 14,
                        padding: "5px 10px",
                        width: "100%",
                        outline: 0,
                        "::placeholder": {
                          color: palette.sealBlue,
                        },
                        ":focus": {
                          borderColor: color,
                        },
                      }}
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Title', id: '9a9+ww',
                      })}
                      {...input}
                    />
                    <ResourceFieldError meta={meta} />
                  </>
                )}
              </Field>

              <Margin mt={10}>
                {values.type === "download" && (
                  <Field name="url">
                    {({ input, meta }) => (
                      <>
                        <Filestack
                          onSuccess={({ filesUploaded }: any) => input.onChange(filesUploaded[0].url)}
                          customRender={({ onPick }: any) => (
                            <Button color={palette.primary} textColor="#fff" size="xsmall" onClick={onPick}>
                              <FormattedMessage defaultMessage="Upload File" id="ryDJAn" />
                            </Button>
                          )}
                        />
                        {input.value && (
                          <Margin mt={5}>
                            <Anchor
                              css={{
                                fontSize: 12,
                                color: palette.primary,
                                wordBreak: "break-word",
                              }}
                              href={input.value}
                              external="outside-govlaunch"
                            >
                              {input.value}
                            </Anchor>
                          </Margin>
                        )}

                        <ResourceFieldError meta={meta} />
                      </>
                    )}
                  </Field>
                )}

                {values.type === "link" && (
                  <Field name="url">
                    {({ input, meta }) => (
                      <>
                        <input
                          css={{
                            height: 42,
                            borderRadius: 5,
                            border: `1px solid ${palette.lightestGray}`,
                            fontSize: 14,
                            padding: "5px 10px",
                            width: "100%",
                            outline: 0,
                            "::placeholder": {
                              color: palette.sealBlue,
                            },
                            ":focus": {
                              borderColor: color,
                            },
                          }}
                          type="url"
                          placeholder="http://"
                          {...input}
                        />
                        <ResourceFieldError meta={meta} />
                      </>
                    )}
                  </Field>
                )}
              </Margin>
            </div>

            <div
              css={{
                display: "flex",
              }}
            >
              <Button color={color} type="submit" textColor="#fff">
                <FormattedMessage defaultMessage="Save" id="jvo0vs" />
              </Button>
              <div
                css={{
                  width: 15,
                }}
              />
              <Button color={color} theme="secondary" onClick={onClose}>
                <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
              </Button>
            </div>
          </div>
        </form>
      )}
    </FinalForm>
  );
};

const ResourceFieldError: React.FunctionComponent<IResourceFieldError> = ({ meta }) => {
  const error = meta.error || meta.submitError;

  if (!meta.touched || !error) {
    return null;
  }

  return (
    <p
      css={{
        margin: "5px 0 0 0",
        color: palette.red,
        fontSize: 11,
        textTransform: "uppercase",
      }}
    >
      {error}
    </p>
  );
};

export default ResourceForm;
