import React, { Fragment, ReactNode, createContext, ReactElement } from "react";
import Dotdotdot from "~/components/text/Dotdotdot";
import * as palette from "@govlaunch/palette";
import Popover from "@govlaunch/popover";
import { Toggle } from "react-powerplug";
import { IFlatResource } from "~/types/types";
import useShowMoreCounter from "~/lib/hooks/useShowMoreCounter";
import ResourceForm from "~/components/resource/ResourceForm";
import { NoResources } from "~/components/profile/EmptyBlocks";
import { FontIcon, LinkIcon, DocumentIcon } from "~/components/product/resources/icons";
import { useToggle } from "react-use";
import { Anchor } from "@govlaunch/web";
import { FormattedMessage } from "react-intl";

const ResourcesFormContext = createContext({
  isOpen: false,
  toggle: () => {},
});

interface IResourceProps {
  style?: React.CSSProperties;
  children: ReactNode;
  onClick?: () => any;
}

interface IShowMoreProps {
  onClick?: () => any;
}

interface IRemoveResourceButtonProps {
  onClick: () => any;
}

interface IResourcesProps {
  resources: IFlatResource[];
  color?: string;
  dataIntercomTarget?: string | null;
  onAdd: (resource: IFlatResource) => any;
  onRemove: (resource: IFlatResource) => any;
  viewOnly?: boolean;
}

interface IResourceListProps {
  resources: IFlatResource[];
  color: string;
  dataIntercomTarget?: string | null;
  onAdd: (resource: IFlatResource) => any;
  onRemove: (resource: IFlatResource) => any;
  viewOnly: boolean;
}

const Resources = ({
  resources,
  onAdd,
  onRemove,
  color = palette.innovatorBlue,
  dataIntercomTarget,
  viewOnly = false,
}: IResourcesProps) => {
  return (
    <ResourceList
      resources={resources}
      onRemove={onRemove}
      onAdd={onAdd}
      color={color}
      dataIntercomTarget={dataIntercomTarget}
      viewOnly={viewOnly}
    />
  );
};

const Resource = ({ children, ...props }: IResourceProps) => {
  return (
    <div
      css={{
        padding: 15,
        display: "flex",
        alignItems: "center",
        position: "relative",
        cursor: "pointer",
        borderTop: `solid 1px ${palette.lightestGray}`,
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.01)",
        },
      }}
      {...props}
    >
      {children}
    </div>
  );
};

const RemoveResourceButton = ({ onClick }: IRemoveResourceButtonProps) => {
  return (
    <button
      type="button"
      css={{
        backgroundColor: "transparent",
        outline: "none",
        cursor: "pointer",
        type: "button",
        right: 9,
        position: "absolute",
        width: 25,
        height: 25,
        lineHeight: "20px",
        color: palette.red,
        fontWeight: 500,
        fontSize: 24,
        border: 0,
        borderRadius: "100%",
      }}
      onClick={onClick}
    >
      &times;
    </button>
  );
};

interface IResoucesFormContextProviderProps {
  children: ReactElement;
}
export function ResourcesFormContextProvider({ children }: IResoucesFormContextProviderProps) {
  const [isOpen, toggle] = useToggle(false);

  return (
    <ResourcesFormContext.Provider
      value={{
        isOpen,
        toggle,
      }}
    >
      {children}
    </ResourcesFormContext.Provider>
  );
}

export const ResourcesFormContextConsumer = ResourcesFormContext.Consumer;

const ResourceList = ({ resources, color, onRemove, onAdd, dataIntercomTarget, viewOnly }: IResourceListProps) => {
  const { offset, showMore, hasMore } = useShowMoreCounter(resources.length, 4);
  const resourcesSlice = resources ? resources.slice(0, offset) : [];

  if (resources.length === 0 && viewOnly) {
    return null;
  }

  if (resources.length === 0) {
    return (
      <ResourcesFormContextConsumer>
        {({ isOpen, toggle }) => (
          <Popover
            boxStyle={{
              boxShadow: "rgba(34, 35, 40, 0.2) 0px 10px 49px 0px",
            }}
            placement="right"
            isOpen={isOpen}
            onClickOutside={() => {
              const filestackIsOpen = document.getElementById("__filestack-picker");

              if (!filestackIsOpen) {
                toggle();
              }
            }}
            render={() => (
              <ResourceForm
                color={color}
                onSubmit={resource => {
                  onAdd(resource as IFlatResource);
                  toggle();
                }}
                onClose={toggle}
              />
            )}
          >
            {({ innerRef }: { innerRef: React.RefObject<HTMLSpanElement> }) => (
              <NoResources innerRef={innerRef} onAddResource={toggle} data-intercom-target={dataIntercomTarget} />
            )}
          </Popover>
        )}
      </ResourcesFormContextConsumer>
    );
  }

  return (
    <div
      css={{
        backgroundColor: palette.white,
        borderRadius: 5,
        border: `solid 1px ${palette.lightestGray}`,
      }}
      data-intercom-target={dataIntercomTarget}
    >
      <div
        css={{
          padding: 15,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h2
          css={{
            textTransform: "uppercase",
            fontSize: 12,
            color: palette.sealBlue,
            letterSpacing: 0.5,
            fontWeight: 500,
            margin: 0,
          }}
        >
          <FormattedMessage defaultMessage="Resources" id="c/KktL" />
        </h2>

        {!viewOnly && (
          <ResourcesFormContextConsumer>
            {({ isOpen, toggle }) => (
              <Popover
                boxStyle={{
                  boxShadow: "rgba(34, 35, 40, 0.2) 0px 10px 49px 0px",
                }}
                placement="right"
                isOpen={isOpen}
                onClickOutside={() => {
                  const filestackIsOpen = document.getElementById("__filestack-picker");

                  if (!filestackIsOpen) {
                    toggle();
                  }
                }}
                render={() => (
                  <ResourceForm
                    onSubmit={resource => {
                      onAdd(resource as IFlatResource);
                      toggle();
                    }}
                    onClose={toggle}
                    color={color}
                  />
                )}
              >
                {({ innerRef }: { innerRef: React.RefObject<HTMLSpanElement> }) => (
                  <span
                    css={{
                      fontSize: 12,
                      color: palette.primary,
                      fontWeight: 600,
                      letterSpacing: 0.2,
                      textTransform: "uppercase",
                      cursor: "pointer",
                    }}
                    ref={innerRef}
                    onClick={toggle}
                  >
                    <FormattedMessage defaultMessage="Add Resource" id="A0sFkU" />
                  </span>
                )}
              </Popover>
            )}
          </ResourcesFormContextConsumer>
        )}
      </div>

      {resourcesSlice.map((resource, index) => {
        return (
          // eslint-disable-next-line
          <Fragment key={index}>
            {resource.__typename === "DownloadResource" && (
              <Resource
                style={{
                  paddingRight: 30,
                }}
              >
                {!viewOnly && <RemoveResourceButton onClick={() => onRemove(resource)} />}
                <div
                  css={{
                    width: 24,
                  }}
                >
                  <DocumentIcon
                    width={24}
                    height={24}
                    color={color}
                    style={{
                      minWidth: 24,
                      minHeight: 24,
                    }}
                  />
                </div>

                <div
                  css={{
                    marginLeft: 10,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Anchor
                    css={{
                      fontSize: 14,
                      color: palette.darkGray,
                      textDecoration: "none",
                      fontWeight: 500,
                      lineHeight: 1.2,
                      "&:hover": {
                        color: palette.darkGray,
                      },
                    }}
                    href={String(resource.url)}
                    download={resource.filename}
                    external="govlaunch"
                  >
                    <Dotdotdot clamp={2}>{resource.title}</Dotdotdot>
                  </Anchor>
                </div>
              </Resource>
            )}

            {resource.__typename === "LinkResource" && (
              <Resource
                style={{
                  paddingRight: 30,
                }}
              >
                {!viewOnly && <RemoveResourceButton onClick={() => onRemove(resource)} />}
                <LinkIcon
                  width={24}
                  height={24}
                  color={color}
                  style={{
                    minWidth: 24,
                    minHeight: 24,
                  }}
                />

                <div
                  css={{
                    marginLeft: 10,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Anchor
                    css={{
                      fontSize: 14,
                      color: palette.darkGray,
                      fontWeight: 500,
                      textDecoration: "none",
                      lineHeight: 1.2,
                      "&:hover": {
                        color: palette.darkGray,
                      },
                    }}
                    href={String(resource.url)}
                    external="outside-govlaunch"
                  >
                    <Dotdotdot clamp={2}>{resource.title}</Dotdotdot>
                  </Anchor>
                </div>
              </Resource>
            )}

            {resource.__typename === "TextResource" && (
              <Toggle>
                {({ on, toggle }) => (
                  <Popover
                    placement="right"
                    isOpen={on}
                    onClickOutside={toggle}
                    boxStyle={{
                      boxShadow: "0 5px 30px 0 rgba(34, 35, 40, 0.2)",
                    }}
                    render={() => (
                      <div
                        css={{
                          padding: 15,
                          maxWidth: 320,
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {resource.body}
                      </div>
                    )}
                  >
                    {({ innerRef }: { innerRef: React.RefObject<HTMLSpanElement> }) => (
                      <div
                        css={{
                          position: "relative",
                          padding: "15px 30px 15px 15px",
                          borderTop: `solid 1px ${palette.lightestGray}`,
                        }}
                      >
                        {!viewOnly && <RemoveResourceButton onClick={() => onRemove(resource)} />}
                        <Resource
                          onClick={toggle}
                          style={{
                            padding: 0,
                            border: 0,
                          }}
                        >
                          <FontIcon
                            width={24}
                            height={24}
                            color={color}
                            style={{
                              minWidth: 24,
                              minHeight: 24,
                            }}
                          />

                          <div
                            css={{
                              marginLeft: 10,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              css={{
                                fontSize: 14,
                                color: palette.darkGray,
                                lineHeight: 1.2,
                                fontWeight: 500,
                                wordBreak: "break-word",
                              }}
                              ref={innerRef}
                            >
                              <Dotdotdot clamp={2}>{resource.title}</Dotdotdot>
                            </span>
                          </div>
                        </Resource>
                      </div>
                    )}
                  </Popover>
                )}
              </Toggle>
            )}
          </Fragment>
        );
      })}

      {hasMore && <ShowMore onClick={showMore} />}
    </div>
  );
};

const ShowMore: React.FunctionComponent<IShowMoreProps> = props => (
  <div
    css={{
      cursor: "pointer",
      padding: 15,
      textTransform: "uppercase",
      color: palette.sealBlue,
      fontSize: 12,
      textAlign: "center",
      borderTop: `solid 1px ${palette.lightestGray}`,
      "&:hover": {
        color: palette.darkGray,
      },
    }}
    role="button"
    aria-label="Show more"
    {...props}
  >
    <FormattedMessage defaultMessage="Show more" id="aWpBzj" />
  </div>
);

export default Resources;
